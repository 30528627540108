<script setup lang="ts">
  import { useBestLocale } from '@/plugins/i18n'
  import { Analytics } from '@vercel/analytics/vue'
  import { SpeedInsights } from '@vercel/speed-insights/vue'
  import NavbarComponent from '@/components/NavbarComponent.vue'

  useBestLocale()
</script>

<template>
  <NavbarComponent />
  <Analytics />
  <SpeedInsights />
  <RouterView />
</template>
